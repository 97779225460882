<template lang="pug">
    div
        template(v-if="userLanguage == 'fr'")
            div.question.block.col-9(:class="{activated: toggleQuestions[0] === true}" @click="toggleQuestions[0] = toggleQuestions[0] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[0] === true}")
                p.text-averta-semibold-20 Comment effectuer une recherche ?
                p.content(v-if="toggleQuestions[0] === true")
                    | Renseignez votre recherche dans la barre du moteur de recherche.
                    br
                    | Appuyez sur la touche « Entrée » du clavier afin d’effectuer une recherche.
                    br
                    | Une pop-up s’ouvre.
                    br
                    | Utilisez les filtres afin de choisir le type de contenu recherché.
                    br
                    | Utilisez le sous menu pour filtrer et orienter votre recherche.
                    br
                    | Cliquez sur un contenu pour le visualiser.

            div.question.block.col-9(:class="{activated: toggleQuestions[1] === true}" @click="toggleQuestions[1] = toggleQuestions[1] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[1] === true}")
                p.text-averta-semibold-20 Comment gérer les notifications ?
                p.content(v-if="toggleQuestions[1] === true") 
                    | Cliquez sur l'icône de notification (la cloche dans le coin supérieur droit de la page). 
                    br
                    | Cliquez sur "Gérer". 
                    br
                    | Une pop-up s'ouvre. 
                    br
                    | Utilisez les options pour activer / désactiver les notifications par courrier électronique. 

        template(v-if="userLanguage == 'en'")
            div.question.block.col-9(:class="{activated: toggleQuestions[0] === true}" @click="toggleQuestions[0] = toggleQuestions[0] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[0] === true}")
                p.text-averta-semibold-20 How to do a research?
                p.content(v-if="toggleQuestions[0] === true") 
                    | Enter what you want to search in the search engine.
                    br
                    | Use "Enter" key to perform a search.
                    br
                    | A pop-up window shows up.  
                    br
                    | Use filters to choose the type of content you are looking for. 
                    br
                    | Use sub-filters to re-filter and narrow down the search result. 
                    br
                    | Click on a content to view.

            div.question.block.col-9(:class="{activated: toggleQuestions[1] === true}" @click="toggleQuestions[1] = toggleQuestions[1] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[1] === true}")
                p.text-averta-semibold-20 How to manage notifications?
                p.content(v-if="toggleQuestions[1] === true") 
                    | Click on the notification icon (the bell in the top right corner of the page). 
                    br
                    | Click on "Manage". 
                    br
                    | A pop-up window shows up. 
                    br
                    | Use the options to activate / deactivate email notifications.

        template(v-if="userLanguage == 'zh-cn'")
            div.question.block.col-9(:class="{activated: toggleQuestions[0] === true}" @click="toggleQuestions[0] = toggleQuestions[0] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[0] === true}")
                p.text-averta-semibold-20 如何搜索？
                p.content(v-if="toggleQuestions[0] === true")
                    | 1. 在搜索栏中输入您想搜索的内容。
                    br
                    | 2. 使用回车键进行搜索。
                    br
                    | 3. 搜索结果窗口将弹出。
                    br
                    | 4. 使用筛选器选择你要寻找的内容类型。
                    br
                    | 5. 使用子筛选器来重新过滤和缩小搜索结果。
                    br
                    | 6. 点击内容查看。

            div.question.block.col-9(:class="{activated: toggleQuestions[1] === true}" @click="toggleQuestions[1] = toggleQuestions[1] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[1] === true}")
                p.text-averta-semibold-20 如何管理通知？
                p.content(v-if="toggleQuestions[1] === true")
                    | 1. 点击通知图标（页面右上角的铃铛）。
                    br
                    | 2. 点击 "管理"。
                    br
                    | 3. 选择激活或取消邮件通知。
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    
    data() {
        return {
            toggleQuestions: []
        }
    },

    computed: {
        userLanguage() {
            return this.$store.getters['language/getUserLanguage']
        }
    }

})
</script>

<style lang="scss" scoped>

@import './question.scss';

</style>